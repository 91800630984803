import { useNavigate, useParams } from "react-router-dom";
import { sectionsQueries, useSectionsService } from "../../../services/sectionService";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import * as THREE from 'three';
import { STLLoader } from 'three/addons/loaders/STLLoader';
import { baseUrl } from "../../../service/api";
import Utils from "../../../utils/utils";
import img_icon from "../../../assets/icons/images_border.svg";
import { getFromIndexedDB, saveToIndexedDB } from "../../../service/indexedDB";
import { blobToArrayBuffer } from "../../../utils/functions";
import axios from "axios";
import { getSectionColor } from "../../../utils/colorsApp";

export function useSectionHook() {
    const sectionService = useSectionsService();

    const [objects, setObjects] = useState([]);
    const [highlightedObject, setHighlightedObject] = useState(null);
    const [refresh, setRefresh] = useState(true);
    const orbitControls = useRef();
    const { id } = useParams();
    const [showRegisterModal, setShowRegisterModal] = useState(false);
    const [opacity, setOpacity] = useState(false);
    const navigate = useNavigate();
    const [progress, setProgress] = useState(0);
    const camera = useRef();

    const [anomalieWindow, setAnomalieWindow] = useState({
        isOpen: false,
        content: null,
        position: null,
    });

    const {
        data: sectionData,
        isLoading: isLoadingSection,
        isRefetching: isRefetchingSection,
        refetch: refetchSection
    } = useQuery(sectionsQueries.GET_BY_ID, () => sectionService.getSectionsById({ id }));

    useEffect(() => {
        if (sectionData?.reference3d) {

            Utils.clear3DObjects(objects, setObjects);

            const tempObjects = [];

            if (sectionData?.reference3d?.section3d?.location) {
                const loader = new STLLoader();

                const loadSTL = async () => {
                    try {
                        const relativePaths = sectionData?.reference3d?.section3d.location.split('/')
                        const cacheKey = `${relativePaths[relativePaths.length - 1]}`;
                        let arrayBuffer;

                        const cachedDataBlob = await getFromIndexedDB(cacheKey, 'home');

                        if (cachedDataBlob) {
                            arrayBuffer = await blobToArrayBuffer(cachedDataBlob);
                        } else {
                            const response = await axios.get(`${baseUrl}/${sectionData?.reference3d?.section3d?.location}`, {
                                responseType: 'arraybuffer',
                                onDownloadProgress: (progressEvent) => {
                                    setProgress((progressEvent.loaded / progressEvent.total) * 100);
                                }
                            });
                            arrayBuffer = response.data;
                            await saveToIndexedDB(cacheKey, new Blob([arrayBuffer], 'home'));
                        }

                        if (arrayBuffer) {

                            const geometry = loader.parse(arrayBuffer);
                            const box = new THREE.Box3().setFromObject(new THREE.Mesh(geometry));
                            const center = new THREE.Vector3();
                            box.getCenter(center);

                            const size = new THREE.Vector3();
                            box.getSize(size);
                            const maxDim = Math.max(size.x, size.y, size.z);

                            const scaleFactor = (1 / maxDim);
                            geometry.scale(scaleFactor, scaleFactor, scaleFactor);

                            const material = new THREE.MeshStandardMaterial({ color: "#cccccc" });
                            const stlMesh = new THREE.Mesh(geometry, material);
                            stlMesh.position.set(-center.x * scaleFactor, -center.y * scaleFactor, -center.z * scaleFactor);
                            stlMesh.castShadow = true;
                            stlMesh.receiveShadow = true;

                            // Adicionar esferas para anomalias
                            if (sectionData.anomalies) {
                                sectionData.anomalies.forEach(anomaly => {
                                    let { position } = anomaly;
                                    
                                    if (position.includes('\"x\":')) {
                                        position = JSON.parse(position);
                                        const objectArea = size.x * size.y; // Área do objeto
                                        
                                        const anomalyArea = (parseFloat(anomaly.area) * objectArea) / parseFloat(sectionData.totalArea) ; // Área da anomalia
                                        const sphereRadius = 200 * scaleFactor
                                        // const sphereRadius = sphereRadius / Math.sqrt(objectArea / Math.PI) / 8; // Fator de escala baseado na área do objeto
                                        
                                        const sphereGeometry = new THREE.SphereGeometry(sphereRadius, 32, 32);
                                        const sphereMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000, transparent: true, opacity: 0.5 });
                                        const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);

                                        sphere.position.set(position.x, position.y, position.z);                                        

                                        tempObjects.push({ anomaly, object: sphere });

                                        if (anomaly.medias && anomaly.medias.length > 0) {
                                            const textureLoader = new THREE.TextureLoader();
                                            textureLoader.load(img_icon, (texture) => {
                                                const iconGeometry = new THREE.PlaneGeometry(0.05, 0.05); // Ajustar o tamanho conforme necessário
                                                const iconMaterial = new THREE.MeshBasicMaterial({ map: texture, transparent: true });
                                                const icon = new THREE.Mesh(iconGeometry, iconMaterial);

                                                const boundingBox = new THREE.Box3().setFromObject(stlMesh);

                                                // Calcular deslocamentos
                                                let offsetX = 0, offsetY = 0, offsetZ = 0;
                                                const offset = sphereRadius + 0.02;

                                                if ((sphere.position.x - sphereRadius) < boundingBox.min.x) {
                                                    offsetX = -offset; // Esquerda
                                                } else if ((sphere.position.x + sphereRadius) > boundingBox.max.x) {
                                                    offsetX = offset; // Direita
                                                }

                                                if ((sphere.position.y - sphereRadius) < boundingBox.min.y) {
                                                    offsetY = -offset; // Inferior
                                                } else if ((sphere.position.y + sphereRadius) > boundingBox.max.y) {
                                                    offsetY = offset; // Superior
                                                }

                                                if ((sphere.position.z - sphereRadius) < boundingBox.min.z) {
                                                    offsetZ = -offset; // Traseiro
                                                } else if ((sphere.position.z + sphereRadius) > boundingBox.max.z) {
                                                    offsetZ = offset; // Frontal
                                                }

                                                icon.position.set(sphere.position.x + offsetX, sphere.position.y + offsetY, sphere.position.z + offsetZ);
                                                const iconRotated = icon.clone();
                                                iconRotated.rotation.set(0, Math.PI, 0);
                                                tempObjects.push({ anomaly, object: icon }, { anomaly, object: iconRotated });

                                                setObjects([...tempObjects]);
                                            });
                                        }
                                    }
                                });
                            }

                            tempObjects.push(
                                { section: sectionData.reference3d.section3d, object: stlMesh },
                            );

                            setObjects(tempObjects);

                            setRefresh(false);
                        }
                    } catch (error) {
                        console.error(`Failed to load STL file for section ${sectionData?.reference3d?.section3d?.token}:`, error);
                    }
                };

                loadSTL();
            }
        }
    }, [sectionData]);

    return {
        general: {
            opacity,
            setOpacity,
            showRegisterModal,
            setShowRegisterModal,
            navigate,
            anomalieWindow,
            setAnomalieWindow,
            id
        },
        section: {
            sectionData,
            isLoading: isLoadingSection || isRefetchingSection || refresh,
            refetchSection
        },
        config3d: {
            orbitControls,
            objects,
            highlightedObject,
            progress,
            camera
        }
    }
}
