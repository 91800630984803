import { ModalMain } from "../styled";
import arrow_bottom_icon from "../../../../assets/icons/arrow_bottom.svg"
import { FontGreyDarkMediumTitle, FontGreyDarkRegular, FontInputGreyDarkRegular, FontPrimaryMediumTitle } from "../../../../utils/typografyApp";
import SecondaryButton from "../../SecondaryButton";
import { FormContainer, RowContainer, SwitchContainer } from "./styled";
import Input from "../../Input";
import Select from "../../Select";
import SwitchButton from "../../SwitchButton";
import PrimaryButton from "../../PrimaryButton";
import { useEffect, useState } from "react";
import PinAnomalies from "../../PinAnomalies";
import success_icon from "../../../../assets/icons/success.svg";
import api from "../../../../service/api";
import { usePriorizationHook } from "./hook";
import { FormProvider } from "react-hook-form";
import { Canvas } from "react-three-fiber";
import { OrbitControls, PerspectiveCamera } from "@react-three/drei";
import { Loading } from "../../Loading";

const PriorizationModal = ({ onClose, setOpacity, section }) => {
    const hook = usePriorizationHook({ section, setOpacity, onClose });

    return (
        <ModalMain>
            <FormProvider {...hook.form.priorizationForm}>
                <form onSubmit={hook.form.priorizationForm.handleSubmit(hook.form.onSubmit)}>
                    <div className={hook.general.tab !== hook.general.tabs[0] && "hidden-content"}>
                        <div className="content">
                            <div className="title">
                                <FontGreyDarkMediumTitle>Registro de Priorização</FontGreyDarkMediumTitle>
                                <img src={arrow_bottom_icon} alt="Icone de adicionar" />
                            </div>
                            <FormContainer>
                                {!section &&
                                    <RowContainer>
                                        <Select
                                            type="text"
                                            placeholder="Selecione a Área"
                                            name="fkSectionsId"
                                            options={hook.sections.sectionOptions}
                                            defaultValue={section.name}
                                        />
                                    </RowContainer>
                                }
                                <RowContainer>
                                    <Input type="text" placeholder="ID" name="internalId" />

                                </RowContainer>
                                <RowContainer>
                                    <Input type="text" placeholder="Área total/parcial" name="totalArea" />
                                    <Input type="text" placeholder="Área priorizada" name="area" />
                                </RowContainer>
                                <RowContainer>
                                    <Input type="date" placeholder="Data planejamento da execução" name="date" />
                                </RowContainer>
                                <RowContainer>
                                    <Input type="number" placeholder="Duração da atividade" name="activityDurationDaysEffective" />
                                    <Input type="number" placeholder="Custo total" name="totalBilling" />
                                </RowContainer>
                                <RowContainer>
                                    <SwitchContainer>
                                        <FontInputGreyDarkRegular>Priorizar Área</FontInputGreyDarkRegular>
                                        <SwitchButton
                                            active={hook.form.watchIsPrioritization}
                                            toggleSelect={hook.form.handleSelectIsPriotized}
                                        />
                                    </SwitchContainer>
                                </RowContainer>
                            </FormContainer>
                        </div>
                        <div className="actions-container">
                            <SecondaryButton onClick={onClose}>Voltar</SecondaryButton>
                            <PrimaryButton type="button" onClick={hook.form.validateForm}>Avançar</PrimaryButton>
                        </div>
                    </div>
                    <div className={`${hook.general.tab !== hook.general.tabs[1] && "hidden-content"}`} >
                        <div className="_3d">
                            {hook.config3d.isLoading && (
                                <div className="progress-bar">
                                    <Loading />
                                    <FontGreyDarkRegular><div className="progress-text"> Carregando: {Math.round(hook.config3d.progress)}%</div></FontGreyDarkRegular>
                                </div>
                            )}
                            <Canvas>
                                <ambientLight intensity={0.5} />
                                <directionalLight
                                    position={[20, 10, 10]}
                                    castShadow
                                    shadow-mapSize-width={1024}
                                    shadow-mapSize-height={1024}
                                    shadow-camera-far={50}
                                    shadow-camera-left={-10}
                                    shadow-camera-right={10}
                                    shadow-camera-top={10}
                                    shadow-camera-bottom={-10}
                                />
                                <PerspectiveCamera ref={hook.config3d.camera} makeDefault position={[-2, 1, 1]} />
                                <OrbitControls ref={hook.config3d.orbitControls} />
                                {hook.config3d.objects?.map((object, index) => (
                                    object.object ?
                                        <primitive
                                            key={index}
                                            object={object.object}
                                            onDoubleClick={hook.config3d.handleObjectClick}
                                        /> :
                                        <></>
                                ))}
                            </Canvas>
                        </div>
                        <div className="float-top">
                            <div className="content">
                                <div className="title underline">
                                    <FontGreyDarkMediumTitle>
                                        <FontPrimaryMediumTitle>Clique duas vezes </FontPrimaryMediumTitle>
                                        na {hook.selectedSection.selectedSection?.name} para registrar uma priorização
                                    </FontGreyDarkMediumTitle>
                                </div>
                            </div>
                            <div className="actions-container">
                                <SecondaryButton onClick={() => hook.general.setTab(hook.general.tabs[0])}>Voltar</SecondaryButton>
                                <PrimaryButton type="submit" disabled={hook.form.isLoading || !hook.config3d.sphere}>
                                    {hook.form.isLoading ? 'Salvando...' : 'Concluir'}
                                </PrimaryButton>
                            </div>
                        </div>
                    </div>


                    <div className={`${hook.general.tab !== hook.general.tabs[2] && "hidden-content"}`} >
                        <div className="content">
                            <div className="title">
                                <FontGreyDarkMediumTitle>Novo registro de priorização feito!</FontGreyDarkMediumTitle>
                                <img src={success_icon} alt="Icone de Sucesso" />
                            </div>
                        </div>
                    </div>
                </form>
            </FormProvider>
        </ModalMain >
    );
}

export default PriorizationModal;




