import { useEffect, useRef, useState } from "react";
import { sectionsQueries, useSectionsService } from "../../../../services/sectionService";
import { useMutation, useQuery } from "react-query";
import { useAnomaliesService } from "../../../../services/anomalieService";
import { anomalieSchema } from "../../../../models/schemas/anomalieSchema";
import { useForm } from "react-hook-form";
import { zodResolver } from '@hookform/resolvers/zod';
import { defectTypesQueries, useDefectTypeService } from "../../../../services/defectTypeService";
import { degradationDegreeQueries, useDegradationDegreeService } from "../../../../services/degradationDegreeService";

import * as THREE from 'three';
import { STLLoader } from 'three/addons/loaders/STLLoader';
import Utils from "../../../../utils/utils";
import { baseUrl } from "../../../../service/api";
import { queryClient } from "../../../../utils/query-client";
import { metricsQueries } from "../../../../services/metricsService";
import { getFromIndexedDB, saveToIndexedDB } from "../../../../service/indexedDB";
import { blobToArrayBuffer } from "../../../../utils/functions";
import axios from "axios";

export function useAnomalieHook({ section, setOpacity, onClose }) {
    const tabs = ['FORM', 'SELECTAREA', 'PHOTOS', 'SUCCESS'];
    const [tab, setTab] = useState(tabs[0]);
    const [refresh, setRefresh] = useState(true);
    const sectionService = useSectionsService();
    const camera = useRef();
    const defectTypeService = useDefectTypeService();
    const degradationDegreeService = useDegradationDegreeService();
    const anomalieService = useAnomaliesService();
    const orbitControls = useRef();
    const [highlightedObject, setHighlightedObject] = useState(null);
    const colors = ['#00ff00', '#ff0000', '#00ff00', '#ff0000'];
    const [objects, setObjects] = useState([]);
    const [sphere, setSphere] = useState(null);
    const [progress, setProgress] = useState(0);
    const [size, setSize] = useState();
    const [scaleFactor, setScaleFactor] = useState();

    const { mutate, isLoading } = useMutation((payload) => anomalieService.createAnomalies({ payload, onSuccess }));
    const anomalieForm = useForm({ resolver: zodResolver(anomalieSchema) });

    const watchPrematureFailure = anomalieForm.watch('prematureFailure');
    const watchFkSectionId = anomalieForm.watch('fkSectionsId');
    const watchMedias = anomalieForm.watch('medias');
    const watchArea = anomalieForm.watch('area');

    const {
        data: sectionsData,
        isLoading: isLoadingSections,
        isRefetching: isRefetchingSections,
        refetch: refetchSections
    } = useQuery(sectionsQueries.GET_ALL_OPTIONS, () => sectionService.getAllSectionsOptions({ search: '', page: 1 }));

    const {
        data: selectedSection,
        isLoading: isLoadingSelectedSection,
        isRefetching: isRefetchingSelectedSection,
        refetch: refetchSelectedSection
    } = useQuery(sectionsQueries.GET_BY_ID, () => sectionService.getSectionsById({ id: watchFkSectionId || section.id }));

    const {
        data: defectTypesData,
        isLoading: isLoadingDefectTypes,
        isRefetching: isRefetchingDefectTypes,
        refetch: refetchDefectTypes
    } = useQuery(defectTypesQueries.GET_ALL_OPTIONS, () => defectTypeService.getAllDefectTypeOptions({ search: '', page: 1 }));

    const {
        data: degradationDegreeData,
        isLoading: isLoadingDegradationDegree,
        isRefetching: isRefetchingDegradationDegree,
        refetch: refetchDegradationDegree
    } = useQuery(degradationDegreeQueries.GET_ALL_OPTIONS, () => degradationDegreeService.getAllDegradationDegreeOptions({ search: '', page: 1 }));

    const sectionOptions = section ? [{ key: section.id, value: section.name }] : sectionsData;

    function onSuccess() {
        setTab(tabs[3]);
        queryClient.refetchQueries(metricsQueries.GET_BY_SECTION_ID);
        queryClient.refetchQueries(sectionsQueries.GET_BY_ID);
        setTimeout(() => {
            onClose();
        }, 3000);
    }

    function handleSelectPrematureFailure() {
        anomalieForm.setValue('prematureFailure', !watchPrematureFailure);
    }

    function validateForm() {
        const requiredFields = ["internalId", "date", "area", "fkDefectTypeId", "fkDegradationDegreeId"];
        const isValid = requiredFields.every(field => anomalieForm.getValues(field));
        if (isValid) {
            setTab(tabs[1]);
            setOpacity(true);
        } else {
            anomalieForm.trigger(requiredFields);
        }
    }

    function onSubmit(payload) {
        mutate({...payload, fkSectionId : watchFkSectionId ?? section.id});
    }

    function handleObjectClick(event) {
        const intersects = event.intersections;
        if (intersects?.length > 0) {
            const intersect = intersects[0];
            const { point } = intersect;
            if (sphere) {
                sphere.position.copy(point);
                anomalieForm.setValue('position', JSON.stringify(sphere.position));
            } else {
                const objectArea = size.x * size.y; // Área do objeto
                                        
                const anomalyArea = (parseFloat(watchArea) * objectArea) / parseFloat(selectedSection.totalArea) ; // Área da anomalia
                const sphereRadius = 200 * scaleFactor; // Raio da esfera baseado na área da anomalia
                

                const sphereGeometry = new THREE.SphereGeometry(sphereRadius, 32, 32);
                const sphereMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000, transparent: true, opacity: 0.5 });
                const newSphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
                newSphere.position.copy(point);


                setObjects((prev) => {
                    if (prev.find(object => object.section === `sphere${prev.length - 1}`)) {
                        return prev;
                    }
                    setSphere(newSphere);
                    return [...prev, { section: `sphere${prev.length}`, object: newSphere }];
                });
                anomalieForm.setValue('position', JSON.stringify(newSphere.position));
            }
        }
    }

    useEffect(() => {
        if (selectedSection?.reference3d) {
            Utils.clear3DObjects(objects, setObjects);

            const tempObjects = [];

            if (selectedSection?.reference3d?.section3d?.location) {
                const loader = new STLLoader();

                const loadSTL = async () => {
                    try {
                        const relativePaths = section.reference3d?.section3d.location.split('/')
                        const cacheKey = `${relativePaths[relativePaths.length - 1]}`;
                        let arrayBuffer;

                        const cachedDataBlob = await getFromIndexedDB(cacheKey, 'home');

                        if (cachedDataBlob) {
                            arrayBuffer = await blobToArrayBuffer(cachedDataBlob);
                        } else {
                            const response = await axios.get(`${baseUrl}/${selectedSection?.reference3d?.section3d?.location}`, {
                                responseType: 'arraybuffer',
                                onDownloadProgress: (progressEvent) => {
                                    setProgress((progressEvent.loaded / progressEvent.total) * 100);
                                }
                            });
                            arrayBuffer = response.data;
                            await saveToIndexedDB(cacheKey, new Blob([arrayBuffer], 'home'));
                        }

                        if (arrayBuffer) {
                            const geometry = loader.parse(arrayBuffer);
                            const box = new THREE.Box3().setFromObject(new THREE.Mesh(geometry));
                            const center = new THREE.Vector3();
                            box.getCenter(center);

                            let size = new THREE.Vector3();
                            setSize(size);
                            box.getSize(size);
                            const maxDim = Math.max(size.x, size.y, size.z);

                            const scaleFactor = (1 / maxDim);
                            setScaleFactor(scaleFactor);
                            geometry.scale(scaleFactor, scaleFactor, scaleFactor);

                            const material = new THREE.MeshStandardMaterial({ color: "#cccccc" });
                            const stlMesh = new THREE.Mesh(geometry, material);
                            stlMesh.position.set(-center.x * scaleFactor, -center.y * scaleFactor, -center.z * scaleFactor);
                            stlMesh.castShadow = true;
                            stlMesh.receiveShadow = true;

                            tempObjects.push(
                                { section: selectedSection.reference3d.section3d, object: stlMesh },
                            );

                            setObjects(tempObjects);

                            setRefresh(false);
                        }
                    } catch (error) {
                        console.error(`Failed to load STL file for section ${selectedSection?.reference3d?.section3d?.token}:`, error);
                    }
                };

                loadSTL();
            }
        }
    }, [selectedSection]);

    useEffect(() => {
        if (watchFkSectionId && tab === tabs[1]) refetchSelectedSection();
    }, [watchFkSectionId, tab]);

    return {
        form: {
            mutate,
            isLoading,
            anomalieForm,
            watchMedias,
            onSubmit,
            validateForm,
            watchPrematureFailure,
            handleSelectPrematureFailure
        },
        general: {
            tab,
            tabs,
            setTab
        },
        selectedSection: {
            selectedSection,
            isLoading: isLoadingSelectedSection || isRefetchingSelectedSection,
            refetchSelectedSection
        },
        sections: {
            refetchSections,
            isLoading: isLoadingSections || isRefetchingSections,
            sectionOptions
        },
        defectTypes: {
            defectTypesData,
            isLoading: isLoadingDefectTypes || isRefetchingDefectTypes,
            refetchDefectTypes
        },
        degradationDegree: {
            degradationDegreeData,
            isLoading: isLoadingDegradationDegree || isRefetchingDegradationDegree,
            refetch: refetchDegradationDegree
        },
        config3d: {
            orbitControls,
            objects,
            highlightedObject,
            colors,
            handleObjectClick,
            sphere,
            camera,
            progress,
            isLoading: refresh
        }
    };
}
